import React from "react"

const FullBleedImage = ({ url, alt, width }) => {
  if (!width) {
    console.error("width with units is required for FullBleedImage")
  }
  return (
    <img
      className="full-bleed"
      src={url}
      alt={alt}
      width={width}
      loading="lazy"
    />
  )
}
export default FullBleedImage
