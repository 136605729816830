import "./src/styles/style.scss"
import "typeface-pt-serif"
import "typeface-nunito"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"

// syntax highlighting color scheme
require("prismjs/themes/prism-tomorrow.css")
// enable line numbers
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

//Prevent fontawesome icons from rendering giant on initial page load
config.autoAddCss = false
