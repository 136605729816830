import FullBleedImage from "../../../../content/blog/components/FullBleedImage.js";
import convertkitOctober from "../../../../content/blog/images/women-make-2020-recap/start-designing-today-first-month-subscribers.jpg";
import header from "../../../../content/blog/images/women-make-2020-recap/start-designing-today-header.jpg";
import resourcesExample from "../../../../content/blog/images/women-make-2020-recap/start-designing-today-resource-page.png";
import * as React from 'react';
export default {
  FullBleedImage,
  convertkitOctober,
  header,
  resourcesExample,
  React
};