import React from "react"
import styled from "styled-components"

const ButtonStyles = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: blue;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

const ButtonLink = () => <ButtonStyles type="button">Fake Link</ButtonStyles>

export default ButtonLink
