import React from "react"
import styled from "styled-components"

const P = styled.p`
  border: 3px dotted #923a56;
  text-align: center;
  padding: 1rem;
  margin: 0 1rem 2rem 1rem;
  font-size: 1.1rem;
  flex: 1;
`

const Button = styled.button`
  border: none;
  background: none;
  color: #923a56;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    color: #632b30;
    cursor: pointer;
  }
`

const HorizontalFlexDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 641px) {
    flex-direction: row;
  }
`

const VerticalFlexDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
`

const FocusOrderFlex = ({ direction }) => {
  return direction === "horizontal" ? (
    <HorizontalFlexDiv>
      <P>
        <Button type="button">
          This is first in the source order and first in the focus order.
        </Button>
      </P>
      <P>
        <Button type="button">
          This is second in the source order and second in the focus order.
        </Button>
      </P>
    </HorizontalFlexDiv>
  ) : (
    <VerticalFlexDiv>
      <P>
        <Button type="button">
          This is first in the source order and first in the focus order, but
          second in the visual order.
        </Button>
      </P>
      <P>
        <Button type="button">
          This is second in the source order and second in the focus order, but
          first in the visual order.
        </Button>
      </P>
    </VerticalFlexDiv>
  )
}

export default FocusOrderFlex
